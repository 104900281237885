import React from 'react';
import CompanyLogo from '@assets/svgs/company-logo.svg';
import EmployeeLogo from '@assets/svgs/employee-logo.svg';
import CompanyLogoSelected from '@assets/svgs/company-logo-filled.svg';
import EmployeeLogoSelected from '@assets/svgs/employee-logo-filled.svg';
import AuthCardLogo from '@components/ka-ui/auth-card-logo/AuthCardLogo';
import makeTypedTranslation from '@hooks/typedTranslations/makeTypedTranslation';
import styles from './AuthUserSelect.module.scss';

type AuthUserSelectProps = {
  userState: { isEmployee?: boolean; setIsEmployee: (isEmployee: boolean) => void };
  disabled?: boolean;
};

const AuthUserSelect: React.FC<AuthUserSelectProps> = ({ userState, disabled }) => {
  const { useTypedTranslation } = makeTypedTranslation('common');
  const { t: translate } = useTypedTranslation('common');
  const { isEmployee, setIsEmployee } = userState;

  return (
    <div className={styles.logosWrap}>
      <AuthCardLogo
        disabled={disabled}
        logo={isEmployee !== undefined && !isEmployee ? CompanyLogoSelected : CompanyLogo}
        setSelectedUser={() => setIsEmployee(false)}
        selected={isEmployee !== undefined && !isEmployee}
        text={translate('authLayout.company')}
      />
      <AuthCardLogo
        disabled={disabled}
        logo={isEmployee ? EmployeeLogoSelected : EmployeeLogo}
        setSelectedUser={() => setIsEmployee(true)}
        selected={isEmployee}
        text={translate('authLayout.employee')}
      />
    </div>
  );
};

export default AuthUserSelect;
