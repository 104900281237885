import React from 'react';
import CheckIcon from '@assets/svgs/check-icon.svg';
import styles from './AuthCardLogo.module.scss';

type AuthCardProps = {
  logo: React.ReactNode;
  text: string;
  setSelectedUser: () => void;
  selected?: boolean;
  disabled?: boolean;
};

const AuthCardLogo: React.FC<AuthCardProps> = ({ logo, setSelectedUser, text, selected, disabled }) => {
  const Logo = logo as any;

  return (
    <button
      disabled={disabled}
      type="button"
      className={`no-button-styles ${styles.buttonWrap} ${selected ? styles.selectedWrap : ''}`}
      onClick={setSelectedUser}
    >
      <div className={styles.authLogoWrap}>
        <Logo className={styles.authLogo} data-testid="logo-icon" />
      </div>
      <div className={`h4 ${styles.text} ${selected ? styles.selectedText : ''}`}>{text}</div>
      {selected && <CheckIcon className={styles.checkIcon} data-testid="check-icon" />}
    </button>
  );
};

export default AuthCardLogo;
