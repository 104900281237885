import React from 'react';
import styles from './AuthCard.module.scss';

type AuthCardProps = {
  header: React.ReactNode | string;
  children: React.ReactNode;
};

const AuthCard: React.FC<AuthCardProps> = ({ header, children }) => {
  return (
    <div className={styles.authCardWrap}>
      <div className={styles.authTitleWrap} data-testid="auth-card-header">
        {header}
      </div>
      <section className={styles.authCardContent}>{children}</section>
    </div>
  );
};

export default AuthCard;
